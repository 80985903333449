/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import {
  mergeCraftPreview,
  resolveTeaching,
  resolveImage,
  resolveMeta,
} from "@dataResolvers";
import { Teachings as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query {
    craft {
      entries(section: "teaching") {
        ... on Craft_teaching_teaching_Entry {
          type: typeHandle
          url
          slug
          title
          teachingExcerpt
          mediaUrl
          mediaEmbed
          postDate
          showDate
          teachingType
          teachingImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entry(section: "teachings") {
        ... on Craft_teachings_teachings_Entry {
          # meta
          url
          slug
          title
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
          metaTitle
          uid
          # hero
          heroHeading
          heroSubheading
          heroBackgroundImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          scrimOpacity
          #featured
          featuredTeaching {
            ... on Craft_teaching_teaching_Entry {
              type: typeHandle
              url
              slug
              title
              teachingExcerpt
              mediaUrl
              mediaEmbed
              postDate
              showDate
              teachingType
              teachingImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query {
  craft {
    entries(section: "teaching") {
      ... on Craft_teaching_teaching_Entry {
        type: typeHandle
        url
        slug
        title
        teachingExcerpt
        mediaUrl
        mediaEmbed
        postDate
showDate
        teachingType
        teachingImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
    entry(section: "teachings") {
      ... on Craft_teachings_teachings_Entry {
        uid
        # hero
        heroHeading
        heroSubheading
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        scrimOpacity
        #featured
        featuredTeaching {
          ... on Craft_teaching_teaching_Entry {
            type: typeHandle
            url
            slug
            title
            teachingExcerpt
            mediaUrl
            mediaEmbed
            postDate
showDate
            teachingType
            teachingImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    title,
    metaTitle,
    metaImage,
    metaDescription,
    slug,
    url,
    heroHeading,
    heroSubheading,
    heroBackgroundImage,
    featuredTeaching,
    scrimOpacity,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      content: heroSubheading,
      image: resolveImage(heroBackgroundImage),
      scrimOpacity,
    },
    featured: featuredTeaching
      .map(e => resolveTeaching(e))
      .map(t => {
        return {
          label: "Featured Teaching",
          title: t.title,
          name: t.title,
          description: t.excerpt,
          showDate: t.showDate,
          type: {
            text: t.type,
            icon: t.type,
          },
          tag: { text: t.type, slug: t.type },
          time: t.time,
          mediaUrl: t.mediaUrl,
          mediaEmbed: t.mediaEmbed,
          image: t.image,
          link: { url: t.url, text: t.label },
        };
      })[0],
    teachings: craft.entries
      .filter(e => e.slug !== featuredTeaching[0]?.slug)
      .map(e => resolveTeaching(e))
      .map(t => {
        return {
          name: t.title,
          description: t.excerpt,
          showDate: t.showDate,
          type: {
            text: t.type,
            icon: t.type,
          },
          time: t.time,
          mediaUrl: t.mediaUrl,
          mediaEmbed: t.mediaEmbed,
          image: t.image,
          button: { url: t.url, text: t.label },
        };
      }),
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
